import { getLang } from '@/utils/'

const ALLSPA = new Set([
  'card-mgmt',
  'cashin',
  'cashout',
  'entrusted-account',
  'money-record',
  'risk',
  'setup',
  'msetup',
  'insurance-fee'
])

redirectFn()
export default function redirectFn() {
  const spaName = window.location.pathname.split('/')[2]
  if (ALLSPA.has(spaName)) {
    window.location.href = `/${getLang()}/${spaName}`
  } else {
    window.location.href = `/${getLang()}/cashin`
  }
}
